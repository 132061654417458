* {
  margin: 0;
  /* overflow: auto */
}

html {
  background-image: url("./images/background.png");
}

.background {
  background-image: url("./images/background.png");
  background-color: #1f2532;
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
  font-size: calc(12px + 2vmin);
  color: white;
  min-height: 100vh;

}

.background2 {
  background-image: url("./images/background5.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: black;
  /* min-height: 50vh; */
  min-width: 100%;
  /* padding-top: 80px; */
  padding-top: 3%;
  padding-bottom: 3%;

}

.background3 {
  background-image: url("./images/background7.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: black;
  /* min-height: 50vh; */
  min-width: 100%;
  padding-top: 3%;
  padding-bottom: 3%;
}

.modal-content {
  background-image: url("./images/background.png");
}


/* NAVBAR */

.navbar {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* padding: 0.5rem 1.5rem; */
  position: absolute;
  z-index: 1;
  width: 100%;
}

.navbar-sticky {
  background: #333;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 1px 1px 1px #222;
  animation: moveDown 0.5s ease-in-out;
  /* display: block; */
}

.navbarLogo {
  max-width: 50%;
  height: auto;
  text-align: left;
  margin-left: 5%;
  display: inline-block;
  padding: 10px;
}


.navbarLinks {
  display: inline-block;
  position: absolute;
  margin-left: 80%;

}

.navbarBTN {
  color: white;
  background: transparent;
  border: none;
  margin: 5px;
  font-family : inherit;
  font-size: 16pt;
}

button:hover {
  cursor: pointer;
}

#greetingDiv {
  /* padding-top: 10%; */
  /* padding-top: 5%; */
  padding-top:5vh;
  padding-bottom: 50px;
  background: transparent;
}

#coffeepic {
  max-width: 90%;
  max-height: 75vh ;
  display:block;
  margin:auto;
}

#greetingsText {
  font-family : "Titillium Web", sans-serif;
  text-transform: bold;
  display:block;
  margin:auto;
  font-size: 30pt;

}

#text {
  font-family : "Titillium Web", sans-serif;
  display:block;
  margin: 0 auto;
  margin-right: 10%;
  font-size: 16pt;
  padding: 3% 3% 3% 0;

}

#resumeBTN {
  background: linear-gradient(100deg, #f9643d, #fe2a77);
  text-transform: uppercase;
  border: 2px solid #fbff07;
  letter-spacing: 1px;
  color: white;
  font-size: 15px;
  padding: 10px 40px;
}

/* .left { */
  /* display: inline-block; */
  /* width: 50%;
  justify-content: center;
  align-items: center;
  padding: 1.5em; */

/* } */

#right {
  /* margin: 0;
  position: absolute;
  width: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
  height: 75vh;
  float: right;
}

#left {
  /* margin: 0;
  position: absolute;
  width: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
  height: 75vh;
  float: left;
}
  /* display: inline-block; */
  /* width: 50%;
  justify-content: center;
  align-items: center;
  padding: 1.5em; */

/* } */

#skillIcons {
    font-size: 7rem;
    color:#1f2532;
    text-align: center;
}


.contactLogos {
  width: 42px;
  margin-bottom: 15px;
}

.contactHeader {
  margin-bottom: 20px;
}

.linkButtons {
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border: 2px solid #232323;
  background-color: #fbff07;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #232323;
}

a.linkButtons:hover {
  background-color: #f0326b;
  color: #232323;
}

.plainContactText {
  margin-top: 5px;
  font-size: 16px;
  color: #232323;
}

.resume:hover {
  background-color: #fbff07;
}

.text-center {
  text-align: center;
  /* margin-bottom: 40px; */
}

#footer {
  height: 15vh;
  text-align: center;
}

#footerTxt {
  margin: auto;
  padding-top: 3%;
  font-size: 12pt
}

#NavbarFinal {
  border-bottom: #fbff07 solid 2px
}

.nav-link {
  color: white;
}

.nav-link:hover {
  color: #f0326b
}

.aboutInfo {
  background: white;
  border: 6px double #000000;
  border-radius: 10px;
  padding: 10px;
  width:80%;
  margin: auto;
  font-size: 16pt;
}

.aboutInfoTitle {
  background: white;
  border: 10px double #000000;
  border-radius: 10px;
  padding: 3px;
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 50px; */
  margin-top: 25px;
  text-align: center;
  width: 80%;
  overflow: hidden;
  display: block;
}

#xBtn {
  /* background: white; */
  border: 6px solid #f0326b;
  border-style: double;
  border-radius: 10px;
  padding: 8px;
  color: white;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  margin-top: 25px;
  text-align: center;
  overflow: hidden;
  display: block;
}

#xBtn:hover {
  background: #f0326b;
  color: #000000;
}

.main-modal {
  min-width: 90%;
  border: 8px double #f0326b;
  border-radius: 10px;
}

.main-modal2 {
  min-width: 90%;
  border: 8px solid #fbff07;
  border-style: double;
  border-radius: 10px;
}

.centerThis {
  margin:auto;
}

.devicon- {
  display:block;
}

.divider {
  background-color: #fbff07;
  /* background-color:white; */
}

.break {
    white-space: pre-line;
}

.turnItWhite {
  color: white;
  text-align: center;
}

.yearTitle {
  color: white;
  font-size: calc(28px + 2vmin);
  font-family : "Titillium Web", sans-serif;
  text-transform: bold;
  padding: 3px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  /* margin-top: 70px; */
  text-align: center;
  overflow: hidden;
  display: block;
  -webkit-text-stroke-color: rgb(39, 34, 34);
  text-shadow:
  2px 2px 0 #000,
-1px -1px 0 #000,  
 1px -1px 0 #000,
 -1px 1px 0 #000,
  1px 1px 0 #000;
}

.centerThis {
  margin: auto;
}

.portfolioTitle {
  color: white;
  font-size: calc(28px + 2vmin);
  font-family : "Titillium Web", sans-serif;
  text-transform: bold;
  padding: 3px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  overflow: hidden;
  display: block;
  -webkit-text-stroke-color: rgb(39, 34, 34);
  text-shadow:
  2px 2px 0 #000,
-1px -1px 0 #000,  
 1px -1px 0 #000,
 -1px 1px 0 #000,
  1px 1px 0 #000;
}

.btn .btn-primary .btn-large {
  background-color:red;
  color:black;
}

.portfolioPic {
  border: 8px double #f0326b;
  border-radius: 10px;
  max-width: 100%;
  height: auto;
}

.topSpace {
  margin-top: 10px;
}

.makeItWhite {
  color: white
}

.portfolioBtn {
  margin: auto;
  width: 50%;
  text-align: center;
}

#closeBtn {
  color: white;

}

#closeBtn:hover {
  background-color: #f0326b;
}

#closeBtn2 {
  color: white;
}

#closeBtn2:hover {
  background-color: #fbff07;
  color: black;
}

.size {
  height: 300px;
}